html, #footer {
    background: $footer-background
}

body.fixed-nav {
    padding-top: 70px;
}

.container {
	width:100%;
    max-width: $max-desktop-width;
}

#header-navbar{
    .container{
        padding-left: 0;
        padding-right: 15px;
    }
}