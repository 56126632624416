@each $label, $map in $iet-purple-map {

	$i: 20;
	$text-color: #000;

	@each $child-label, $child-map in $map {

		@if $i > 60 {

			$text-color: rgba(255,255,255,0.9);
	
		}
		@else{
	
			$text-color: rgba(83,39,78,0.9);
	
		}
		
		.iet-purple-#{$label}-#{$i}{
			background-color: map-get($map, $i);
			color: $text-color;
		}

		$i: $i + 20;

	}

}

@each $label, $map in $iet-blue-map {

	$i: 20;
	$text-color: #000;

	@each $child-label, $child-map in $map {

		@if $label == 'dark' {

			@if $i > 40{
				$text-color: rgba(255,255,255,0.9);
			}
			@else{
				$text-color: rgba(0,58,102,0.9);
			}

		}
		@else{
	
			$text-color: rgba(0,58,102,0.9);
	
		}
		
		.iet-blue-#{$label}-#{$i}{
			background-color: map-get($map, $i);
			color: $text-color;

			@if $i == 100 and $label == 'dark'{

				&.alternative-text-color{
					color: rgba(34,187,224,0.9);
				}

			}
		}

		$i: $i + 20;

	}

}


@each $label, $map in $iet-green-map {

	$i: 20;
	$text-color: #000;

	@each $child-label, $child-map in $map {

		@if $label == 'dark' {

			@if $i > 40{
				$text-color: rgba(255,255,255,0.9);
			}
			@else{
				$text-color: rgba(16,50,43,0.9);
			}
	
		}
		@else{
	
			$text-color: rgba(16,50,43,0.9);
	
		}
		
		.iet-green-#{$label}-#{$i}{
			background-color: map-get($map, $i);
			color: $text-color;

			@if $i == 100 and $label == 'dark'{

				&.alternative-text-color{
					color: rgba(170,200,30,0.9);
				}

			}
		}

		$i: $i + 20;

	}

}

@each $label, $map in $iet-orange-map {

	$i: 20;
	$text-color: #000;

	@each $child-label, $child-map in $map {

		@if $i > 60 {

			$text-color: rgba(255,255,255,0.9);
	
		}
		@else{
	
			$text-color: rgba(103,31,32,0.9);
	
		}
		
		.iet-orange-#{$label}-#{$i}{
			background-color: map-get($map, $i);
			color: $text-color;
		}

		$i: $i + 20;

	}

}


.iet-black-20{
	background-color: rgb(204,204,204);
	color: rgba(0,0,0,0.9);
}

.iet-black-40{
	background-color: rgb(153,153,153);
	color: rgba(255,255,255,0.9);
}

.iet-black-60{
	background-color: rgb(102,102,102);
	color: rgba(255,255,255,0.9);
}

.iet-black-80{
	background-color: rgb(51,51,51);
	color: rgba(255,255,255,0.9);
}

.iet-black-100{
	background-color: rgb(0,0,0);
	color: rgba(255,255,255,0.9);
}