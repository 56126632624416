iet-global-bar, .iet-global-bar{

	width:100%;
	position:fixed;
	top:0;
	display:block;
	z-index: 11;
	box-shadow: 0px -3px 5px 4px rgba(0, 0, 0, 0.75)!important;
	padding:0 8px;
	height:56px;

	@include MQ(576px){
		padding:0 12px;
	}

	@include MQ($min-desktop-width){
		padding:0 30px;
	}

	.container{
		display:flex;
		flex-wrap:wrap;
		justify-content: space-between;

		&:before{
			display:none;
		}
	}

	.navbar-header{

		@include MQ(1020px){
			display:none;
		}

	}

	#main-menu{

		width:100%;

		.zone-navigation{
			display:flex;
			justify-content: space-between;
			// transform: translateY(-9px);
			height:56px;
			align-items:center;
		}

		.shopping-cart-button{
			display: flex;
			align-items: center;
		}

		.dropdown-bar{
			margin: 0;
			padding:0;
			height:100%;
		}

		.widget-LoginBox{
			transform: translate3d(120px, 12px, 0);
			position: absolute;
			right: 250px;
			margin:0;
			padding:0;
		}
	}

	.iet-sites{
		display:flex;
		max-width:124px;
		height:100%;

		>ul{
			border:0;

			li{

				height:100%;

				&:after{
					content:'';
					background:svg_color("images/arrow-down.svg", #ffffff);
					background-repeat: no-repeat;
					position:absolute;
					right:10px;
					top: calc(100% - 39px);
					width: 17px;
					height: 30px;
					// z-index:1002
				}

				a{
					font-weight:normal!important;
					padding: 17px 15px 16px 15px!important;
				}

				&.dropdown.open{

					background-color: map-get($map: $iet-black-map, $key: 20 );

					&:after{
						background:svg_color("images/arrow-up.svg", #000000);
						background-repeat:no-repeat;
					}

					div.open-on-hover a{

						background-color: transparent !important;
						border-color: map-get($map: $iet-black-map, $key: 20 )!important;
						color: rgba(0, 0, 0, 0.9)!important;
						text-decoration:none;

					}

					ul{
						display:flex;
					}

				}

			}

			ul{
				position:relative;
				flex-wrap: wrap;
				flex-direction: row;
				position:absolute;
				z-index:10;
				padding: 80px 0 20px 0;
				height:calc(100vh - 56px);
				width:calc(100vw - (#{$side-margin-size}));
				border:none !important;
				
				@include MQ($min-desktop-width){
					padding:0;
					height:auto;
					width:750px;
				}

				li{
					width:calc(100% - 40px);
					margin:0 20px;
					border:1px solid map-get($map: $iet-black-map, $key: 100);
					border-bottom:0px;

					&:after{
						display:none;
					}

					&:last-of-type{
						border-bottom:1px solid map-get($map: $iet-black-map, $key: 100);
					}

					&#close-site-nav{
						position: absolute;
						top:0;
						right:0;
						border: 0;
						width: auto;
						margin: 0;

						@include MQ($min-desktop-width){
							display:none;
						}
					}

					@include MQ($min-desktop-width){
						border:0;
						margin:0;
						width:250px;

						&:last-of-type{
							border-bottom:0;
						}
					}

					a{

						text-decoration: none;
						background-color: map-get($map: $iet-black-map, $key: 20 )!important;
						color: rgba(0, 0, 0, 0.9)!important;
						width:100%;
						padding:20px;

						&:hover{
							background-color: map-get($map: $iet-black-map, $key: 100 )!important;
							color:rgba(255,255,255, 0.9)!important;
						}
					}
				}

			}
		}

		.nav{


			&:before, &:after{
				display:none;
			}

		}

	}
	

	.widget-LoginBox{

		font-size:1em;
		display:flex;
		flex-direction: column;
		justify-content: center;
		top: calc(100% - 68px);
		height: 56px;

		i{
			display:none;
		}

		@include MQ($min-tablet-width){
			order: 2
		}

		.user-welcome{
			font-weight: bold;
			display:block;
			font-size:inherit;
			margin:0;
		}

		.user-logout{
			display:block;
			color:rgba(255,255,255,0.9);
			text-decoration: none;
			font-size:inherit;
			margin-bottom:0;
		}

		.login-widget{

			>a{
				&:before{
					content:'Welcome!';
					width:100%;
					display:inline-block;
					color:rgba(255,255,255,0.9);
					text-decoration: none!important;
					font-size:inherit;
					margin-bottom:0;
					font-weight: bold;

					&:hover{
						text-decoration: none!important;
					}
				}
			}
		}

		a{
			color:inherit;
			text-decoration: inherit;
			transition: 0.25s all ease;

			&:hover{
				color: map-deep-get($iet-purple-map, "light", 100);
				text-decoration: underline;
			}
		}

	}

	.widget-ProductSearch{

		display:flex;
		width:100%;
		flex-basis: 100%;
		border-top:1px solid #fff;
		margin:0!important;
		padding:0!important;

		@include MQ($min-tablet-width){
			flex-basis: auto;
			width:auto;
			order:0;
			border-top:0;
			flex-grow:2;
		}

		form{
			margin:0 auto;
			width:90%;
			max-width:500px;
			display:flex;
			align-items: center;
			transform: translateX(-60px);
		}

		fieldset{
			width:100%;
		}

		input, button{
			border-radius: 0!important;

			&:focus{
				outline:0;
				box-shadow: none;
			}
		}

		input[type=text]{
			display:inline-block;
			width:100%;
			border:0;
			height:35px;
			margin-right:10px;
			margin-top:0;
			background-color:map-get($map: $iet-black-map, $key: 60);
			color: rgba(255,255,255,0.9);
			padding:0 10px;
			font-size: 16px;

			&::placeholder{
				color:inherit;
			}

			&::-ms-input-placeholder{
				color:inherit;
				opacity: 1;
			}

		}

		button{

			@extend %button;

			width:30%;
			display:inline-block;
			background:$iet-light-button !important;
			color:rgba(0,0,0,0.9) !important;
			margin-left:10px !important;
			line-height: 35px;
			padding:0!important;

			&:hover{
				background:$iet-dark-button !important;
				color:rgba(255,255,255,0.9) !important;
			}

			&:after{
				content:"Search";
				display:inline-block;
				font-size: 16px;
			}

			span{
				display:none;
			}
		}

	}

	.widget-Cart{

		ul.mini-cart-area{

			padding: 0;
			top:65px;

		}

	}

}