//
// map-deep-get($map, $keys)
//
// An easy way to fetch a deep value in a multi-level map. Works much like
// map-get() except that you pass multiple keys as the second parameter to
// go down multiple levels in the nested map.
//
@function map-deep-get($map, $keys...) {
	$value: $map;

	@each $key in $keys {
		$value: map-get($value, $key);
	}
	
	@return $value;
}