@import "./functions";
@import "./mixins";
@import "./variables";
@import "./colour-palette";

@import "./structure";
@import "./typography";


@import "./components/global-bar";
@import "./components/button";
@import "./components/footer";



.widget-UpdateMyPreferences h3:before {
    font-family: 'FontAwesome';
    content: '\f007';
}

.widget-ViewMyActivity {
    margin-left: 3%;
    margin-right: 3%;
}

.widget-ManageMyAccount {
    margin-left: 0;
    margin-right: 0;
}

.product-title {
    color: $page-text-color-dark;
}

#footer h3 {
    color: $footer-text-color;
}

.title-box {
    background: $primary-color;
}

.print-button:hover, .print-button {
    color: $navigation-bar-text-color;
}

.carousel .carousel-control, .carousel .carousel-control:hover {
    color: $primary-color !important;
}

.email-list-text p {
    color: $primary-color-hover;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover,
.pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: $primary-color;
    border-color: $primary-color;
}

.navbar-default {
    // background: $navigation-bar-background;
    border: none;
    box-shadow: none;
}


html, #footer {
    background: $footer-background
}

.widget-UpdateMyPreferences h3:before, .widget-ManageMyAccount h3:before, .widget-ViewMyActivity h3:before, .account-box i {
    color: $primary-color;
}

.zone-footer a, .zone-footer a:hover, .zone-footer div, .zone-footer a, #footer a {
    color: $footer-text-color;
}

a:hover, a:focus {
    color: $secondary-color-hover
}

h5 a, h5 a:hover {
    color: $secondary-color;
}

.product-price, .cart-product-price {
    color: $page-text-color;
}

.btn-link:hover, .btn-link:active, .btn-link:focus {
    color: $primary-color-hover;
}

.btn-link {
    color: $primary-color;
}

.widget-navigation .dropdown-menu .mini-cart .mini-cart-options .checkout-button.btn-primary,
.widget-navigation .dropdown-menu .mini-cart .mini-cart-options .checkout-button.btn-primary:hover {
    background: $primary-color;
}

.btn-primary, .btn-primary, .btn-primary {
    background-color: $primary-color;
    outline: none;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: $primary-color-hover;
    outline: none;
}

.fa.in-stock {
    color: $primary-color;
}


.fa.delayed {
    color: $secondary-color;
}

.fa.out-of-stock {
    color: $primary-color;
}

#footer a i {
    color: $footer-icon-color;
}


.cart-product-list .cart-item-delete .remove-item {
    background-color: $secondary-color;
}



.navbar-brand img {
    max-width: 300px;
    max-height: 71px;
}

body.fixed-nav {
    padding-top: 70px;
}

.pre-footer {
    background: $pre-footer-background-color;
}

.navbar-default .navbar-nav > li > a {
    color: $secondary-color;
    font-weight: bold;

    &:focus, &:hover {
        color: $secondary-color-hover;
        font-weight: bold;
    }
}

.widget-cart-widget {
    .shopping-cart-button {
        .cart-button i {
            color: $navigation-bar-icon-color;
        }

        .cart-count {
            color: $primary-color;
        }

        .cart-money {
            color: $navigation-bar-text-color;
        }
    }
}

.open-auth-options div.open-auth-separator {
    background-color: $secondary-color;
}

ul.checkout-chain {
    background: $primary-color;

    li, li:hover {
        border-bottom: 30px solid $primary-color;
        border-top: 30px solid $primary-color;
    }

    li.active, li.active:hover {
        border-bottom: 30px solid $primary-color-hover;
        border-top: 30px solid $primary-color-hover;
        background: $primary-color;
    }

    li.active.first, li.active.first:hover, li.active + li {
        background: $primary-color-hover;
    }
}

.csspinner.ringed:after {
    border-right: 4px solid $secondary-color;
    border-top: 4px solid $primary-color;
    border-left: 4px solid $primary-color;
    border-bottom: 4px solid $primary-color;
    opacity: 1;
}

.btn-group .btn-primary:active, .btn-group .primaryAction:active, .btn-group .btn-primary.active, .btn-group .primaryAction.active {
    background-color: #000;
}

.btn-primary, .primaryAction {
    background-color: #000;

    &:hover {
        text-decoration: underline;
        box-shadow: none;
        border-color: transparent;
    }
}


.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: $primary-color;
}


.btn-primary.subscribe-button {
    background: $secondary-color;

    &:hover, &:active, &:focus {
        background: $secondary-color-hover;
    }
}

.glyphicon-search {
    color: $navigation-bar-text-color;
}

.zone-navigation {
    .menu {
        & > .active > .dropdown-bar > .expand-arrow-companion > a, & > li > .dropdown-bar > .expand-arrow-companion > a, & > .open > a, & > .active > a, & > li > a, &.menu-my-account-links > a {
            color: $navigation-bar-text-color;
            font-weight: bold;

            &:hover, &:focus {
                font-weight: bold;
                color: $navigation-bar-text-color;
                background: $navigation-bar-background-color-hover;
            }
        }

        & > .open > .dropdown-bar > .expand-arrow-companion > a {
            font-weight: bold;
            color: $navigation-bar-text-color;
            background: $navigation-bar-background-color-hover;
        }
    }
}

.pager > .active > a, .pager > .active > span, .pager > .active > a:hover, .pager > .active > span:hover, .pager > .active > a:focus, .pager > .active > span:focus {
    background-color: $primary-color;
    border-color: $primary-color;
}

.pager > li > a, .pager > li > span {
    color: $primary-color;
}

.navbar-brand {
    margin: 30px;
}

.navbar-brand.modify-for-print {
    img {
        width: 100px;
    }

    margin-bottom: 0;
    margin-top: 0;
}

.modify-for-print {
    .back-button {
        display: none;
    }

    .content-row.prime {
        padding-top: 0;
    }

    .new-page-print {
        page-break-before: auto;
    }

    .form-group > h4 {
        display: none;
    }


    .product-list, .payment-list {
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;
        border: 0;
        box-shadow: none;
    }

    .product-price, .cart-product-price {
        font-size: 12px;
        font-weight: normal;
    }

    .title-box {
        padding: 0;
        margin-bottom: 0;
        margin-top: 0;

        h4, h5 {
            font-size: 12px;
        }
    }

    .order-detail-item {
        padding-top: 0;
    }

    .order-detail-payment-breakdown {
        padding-top: 4px;
    }

    h4 {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 6px;
        border-bottom: solid 1px black;
        padding-bottom: 3px;
        margin-bottom: 2px;
    }

    .order-detail-billing, .order-detail-shipping-address {
        h4 {
            font-size: 12px;
        }

        font-size: 10px;
        padding-top: 0;
    }
}

.zone-navigation {
    a {
        color: $navigation-bar-link-color;

        &:hover, &:focus {
            color: lighten($navigation-bar-link-color, 10%);
        }
    }

    .widget-login-widget {
        margin-top: 8px;

        div, span {
            color: $navigation-bar-text-color;
        }
    }
}

.before-main-row {
    // background: $lower-navigation-bar-background;
    .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: lighten($lower-navigation-bar-background, 25%);
    }


    a {
        color: $lower-navigation-bar-text-color;

        &:hover {
            color: $lower-navigation-bar-text-color;
        }
    }

    margin-left: -45px;
    margin-right: -45px;
}

.featured-row {
    margin-left: 0;
    margin-right: 0;
}

#layout-wrapper {
    max-width: none;
}

.zone-navigation .widget-search-form {
    width: 25%;
    margin-left: 20px;
}

.container {
    max-width: none;
}

#header-navbar {
    .container {
        padding-left: 0;
        padding-right: 15px;
    }
}


i h2 {
    margin-top: 0;
    font-size: 25px;
}

.add-to-cart {
    margin-left: 15px;
    margin-bottom: 10px;
}

.product-row {
    .image-frame.image-frame-col {
        padding: 0;

        img {
            width: 100%;
        }
    }
}

@media (min-width: 768px) {
    .image-frame-col {
        width: 18%;
        margin-right: 2%;
        margin-left: 15px;
    }
}

@media (min-width: 768px) {
    .product-row .product-description.product-description-col-narrow {
        float: left;
    }
}

.aside-first-col, .aside-second-col {
    width: 16.66%;
}
// Menu in side bar
.zone-aside-second, .zone-aside-first {
    .widget-aside-first {
        h3 {
            margin-left: 5px;
        }
    }

    .menu {

        & > li {
            a,
            .dropdown-bar > .expand-arrow-companion > a,
            & > ul > li > a,
            & > ul > li > .dropdown-bar > .expand-arrow-companion > a {
                color: #888888;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 5px;
                clear: both;

                &:hover, &:focus {
                    background: transparent;
                }
            }

            .expand-arrow.dropdown-toggle {
                display: none;
            }

            &.active {
                & > a,
                & > .dropdown-bar > .expand-arrow-companion > a {
                    color: $secondary-color;
                }
            }


            & > a {
                margin-left: 0;
            }
        }
    }
}

.aside-second-col .well {
    border: none !important;
    box-shadow: none !important;
}

.content-item .image-frame {
    margin-right: 15px;
    padding-left: 0;
}

.content-item > .add-to-cart {
    max-width: none;
    width: 60%;

    @media(min-width: 768px) {
        max-width: 50%;
        width: 25%;
    }
}

.content-items {
    li {
        padding-left: 15px;
    }
}

.content-items * .content-item .product-details {
    margin: 0;
}

.catalog-product-summary {
    @media(max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.faceted-taxonomy .faceted-product-category-results .pager-info .pager-settings .sort-modes.form-control {
    width: 104px;

    @media(min-width: 768px) {
        width: auto;
    }
}

.menu-featured-menu {

    li {
        float: left;
        width: 50%;
        clear: none;
    }
}

@media(min-width: 768px) {
    .menu-featured-menu {

        li {
            width: auto;
        }
    }
}

.navbar-collapse {
    max-height: calc(100vh - 50px);
    /*padding-right: 15px;
		padding-left: 15px;
		overflow-x: visible;
		border: none;
		box-shadow: none;
		-webkit-overflow-scrolling: touch;*/
}

.widget-navigation-menu-categories {
    display: block;

    @media (min-width: 1020px) {
        display: none;
    }
}

.pager-settings {
    @media (max-width: 1224px) {

        .sort-modes, page-size-options, label {
            font-size: 11px;
        }
    }
}

.faceted-search, .faceted-taxonomy {
    margin-right: -45px;

    @media (max-width: 768px) {
        margin-right: 0;
    }
}

body {
    @media (max-width: 1224px) {
        font-size: 14px;
    }
}

.faceted-search .faceted-search-filters, .faceted-taxonomy .faceted-search-filters {
    @media (max-width: 768px) {
        display: none;
    }
}

@media (max-width: 1020px) {
    .navbar-brand {
        margin-top: 0;
    }
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: #ccc;
}

.navbar-default .navbar-toggle {
    border-radius: 0;
    border: none;
    background: #ccc;

    .icon-bar {
        background-color: #53274e;
        height: 3px;
    }
}

.new-hosted-payment img {
    max-width: 300px;
}

.place-order.payment-confirmation {
    .btn-primary, button {
        max-width: none;
    }
}

iet-global-bar, .iet-global-bar {
    #main-menu {
        @media (max-width: 1020px) {
            position: inherit;
        }
    }


    #main-menu .zone-navigation {
        @media (max-width: 1020px) {
            display: block;
            height: auto;
            background: #333;
            width: 100%;
            margin-left: 7px;
            position: static;
            left: 0;
            top: 55px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 50px;
            box-shadow: 0px 30px 26px -23.5px rgba(0,0,0,0.75) !important;
        
        }

    }

    .widget-LoginBox, .widget-ProductSearch, .iet-sites {
        display: block;

        @media (max-width: 1020px) {
            display: none;
        }
    }

    .widget-LoginBox{
        .login-widget{
            margin-top: 4px;
        }
    }

    .widget-ProductSearch-mobile {
        #q::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: rgba(255,255,255,0.9);
        }

        #q::-moz-placeholder { /* Firefox 19+ */
            color: rgba(255,255,255,0.9);
        }

        #q:-ms-input-placeholder { /* IE 10+ */
            color: rgba(255,255,255,0.9);
        }

        #q:-moz-placeholder { /* Firefox 18- */
            color: rgba(255,255,255,0.9);
        }
    }

    .zone-navigation .widget-login-widget.widget-LoginBox-mobile {
        margin-top: 0;
    }


    .widget-LoginBox-mobile, .widget-ProductSearch-mobile, .widget-iet-sites-mobile {
        display: none;

        @media (max-width: 1020px) {
            display: block;
        }

        .menu-i-e-t-sites {
            width: auto;
            border-left: none;
            border-right: none;
        }
    }

    .widget-ProductSearch button, .primary-btn, .btn-primary, button, .secondary-btn {
        @media (max-width: 1020px) {
            min-width: 0;
        }
    }
}




.faceted-search .faceted-search-results.filters-active, .faceted-taxonomy .faceted-search-results.filters-active, .faceted-search .faceted-product-category-results.filters-active, .faceted-taxonomy .faceted-product-category-results.filters-active {
    @media (max-width: 768px) {
        float: left;
        width: auto;
    }
}

.faceted-search-filters {
    @media (max-width: 1224px) {
        .filter-label {
            font-size: 11px;
        }
    }
}

.widget-left-side-menu-categories {
    display: none;

    @media (min-width: 1020px) {
        display: block;
    }
}

.content-row.prime {
    margin-left: -15px;
    margin-right: -15px;

    @media (max-width: 1020px) {
        margin-left: -30px;
        margin-right: -30px
    }
}

.add-to-cart-container > form {
    float: left;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    width: 100%;

    span, a {
        font-size: 12px;
    }

    .add-to-cart.btn {
        margin-top: 4px;
    }

    .add-to-cart.btn:focus {
        color: white;
    }
}

.subscription-receipt-row.modify-for-print {
    .product-image {
        width: 80px;
    }

    .cart-product-price {
        float: right;
        margin-top: -35px;
        margin-right: 14px;
    }

    .product-list .product-details h3 {
        font-size: 16px;
    }
}

@media print {
    a[href]:after {
        display: none;
        visibility: hidden;
    }
}

.faceted-search-filters {
    border-left: 1px solid #ddd;

    .search-filter {
        background-color: #f4f4f4;
        padding: 10px;
        width: 100%;
        box-sizing: content-box;
    }
}

@media (max-width: 1020px) {
    .zone-navigation .widget-navigation.widget-cart-widget {
        padding: 10px;
    }

    .zone-header {
        position: absolute;
        width: calc(100% - 70px);
        left: 70px;
        right: 0;
        top: 0;
    }
}

.navbar-toggle {
    margin-top: 10px;
}

.widget-ProductSearch-mobile {
    max-width: 300px;
    margin: 0 auto;
    padding-top: 10px;

    .input-group .form-control:first-child {
        border-radius: 0;
    }

    input[type=text] {
        display: inline-block;
        width: 100%;
        border: 0;
        height: 35px;
        margin-right: 10px;
        margin-top: 0;
        background-color: #666;
        color: rgba(255,255,255,0.9);
        padding: 0 10px;
        font-size: 16px;
    }

    button {
        text-align: center;
        min-width: 100px;
        max-width: 500px;
        transition: all .2s;
        text-decoration: none;
        font-weight: bold;
        width: 30%;
        display: inline-block;
        background: #fff !important;
        color: rgba(0,0,0,0.9) !important;
        margin-left: 10px !important;
        line-height: 35px;
        padding: 0 !important;

        &:after {
            content: "Search";
            display: inline-block;
            font-size: 16px;
        }

        .glyphicon-search {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .search-form .input-group {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.container > .navbar-header, .container > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
}

@media (max-width: 416px) {
    .cart-product-list .cart-product-image {
        display: none;
    }
}

@media (max-width: 416px) {
    .container {
        padding-right: 45px;
        padding-left: 45px;
        margin-right: auto;
        margin-left: auto;
    }

    .faceted-search .faceted-search-results.filters-active, .faceted-taxonomy .faceted-search-results.filters-active, .faceted-search .faceted-product-category-results.filters-active, .faceted-taxonomy .faceted-product-category-results.filters-active {
        padding-left: 0;
        padding-right: 0;
    }

    .content-item > .add-to-cart {
        max-width: 50%;
        width: 100%;
        float: right;
    }

    .product-details h3, .product-details h5 {
        float: left;
        margin-top: 4px;
        width: 100%;
    }

    .navbar-nav > li > a {
        padding: 10px;
    }

    .menu-featured-menu li {
        float: left;
        width: auto;
        clear: none;
    }

    .navbar-brand {
        margin-top: 0;
    }

    .faceted-search .faceted-search-results .pager-info .pager-settings, .faceted-taxonomy .faceted-search-results .pager-info .pager-settings, .faceted-search .faceted-product-category-results .pager-info .pager-settings, .faceted-taxonomy .faceted-product-category-results .pager-info .pager-settings {
        padding-right: 0;
    }

    .content-row.prime {
        padding-top: 0;
    }

    h1, h2, h3, h3 a {
        margin-top: 15px;
    }
}

@media (max-width: 360px) {
    .navbar-brand img {
        width: 250px;
    }

    .navbar-nav > li > a {
        font-size: 11px;
    }

    .content-item .image-frame {
        max-width: 35%;
    }

    .faceted-search .faceted-search-results .pager-info .pager-settings, .faceted-taxonomy .faceted-search-results .pager-info .pager-settings, .faceted-search .faceted-product-category-results .pager-info .pager-settings, .faceted-taxonomy .faceted-product-category-results .pager-info .pager-settings {
        padding-left: 0;
    }

    .faceted-search .faceted-search-results .pager-info .pager-settings .page-size-options.form-control, .faceted-taxonomy .faceted-search-results .pager-info .pager-settings .page-size-options.form-control, .faceted-search .faceted-product-category-results .pager-info .pager-settings .page-size-options.form-control, .faceted-taxonomy .faceted-product-category-results .pager-info .pager-settings .page-size-options.form-control, .faceted-search .faceted-search-results .pager-info .pager-settings .sort-modes.form-control, .faceted-taxonomy .faceted-search-results .pager-info .pager-settings .sort-modes.form-control, .faceted-search .faceted-product-category-results .pager-info .pager-settings .sort-modes.form-control, .faceted-taxonomy .faceted-product-category-results .pager-info .pager-settings .sort-modes.form-control {
        max-width: 80px;
        padding: 0;
    }
}

.zone-footer {
    // background-color:#373737;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    article > .utils {
        // background-color:#282828;
        padding: 20px 0;

        ul {
            list-style: none;
            width: 100%;
            text-align: right;

            li {
                display: inline;
                padding-right: 7px;

                &:after {
                    content: '';
                    border-right: 1px solid #fff;
                    padding-right: 10px;
                }

                &:last-of-type {
                    &:after {
                        border-right: 0;
                    }
                }

                a {
                    font-size: 12px;
                }
            }
        }

        p {
            font-size: 10px;
            margin-bottom: 0;
            color: #fff;
        }
    }

    dt, dd {
        text-align: left;
    }
}

.badge-of-honor {
    display: none;
}

.menu-i-e-t-sites {
    width: 200px;
    border-left: 2px solid #000;
    border-right: 2px solid #000;

    li {
        width: 100%;
    }
}

.low-quantity-alert {
    font-size: 12px;
    color: #dc2222;
    font-weight: bold;
}

.btn {
    border-radius: 0px; // There are to be no rounded edges
}

.cart-checkout-options {
    .mini-log-on {
        display: none;
    }
}

.cart-coupon-promotion-row {
    display: none;
}

.price sup {
    opacity: 0.5;
    font-weight: normal;
}

@media (max-width: 768px){
    .product-row .product-description {
        width: 100%;
    }
}

// CCC styling mostly copied from styles.min.css?v=1.1.7 on theiet.org for consistency
#ccc #ccc-icon {
    bottom: 20px;
    background: initial !important;
}

.ccc-panel {
    padding-bottom: 40px;
}

#ccc-button-holder {
    display: flex;
    justify-content: flex-end;
}

#ccc-recommended-settings {
    background-color: #fff !important;
}

#ccc-recommended-settings span {
    color: #111125 !important;
}

#ccc-dismiss-button {
    background: initial !important;
}

#ccc-dismiss-button span {
    background: initial !important;
    color: #fff !important;
}

/*
    Imports
    
    Keep these at the bottom of the file so
    they correctly overwrite the above styles if they need to
*/
