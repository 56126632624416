@import url("https://use.typekit.net/eag8ezh.css");
//Material icons
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

*{
	font-family: 'europa', Arial, Helvetica, sans-serif;
}

body{
	font-size: 16px;
}

h1, h2, h3, h3 a, h4, h5 {
    color: $primary-color;
}

div, input, legend, p {
    color: $page-text-color;
}

a {
	color: $secondary-color;
	
	&:hover, &:focus {
		color: $secondary-color-hover
	}
}

h5{

	a{
		color: $secondary-color;

		&:hover{
			color: $secondary-color;
		}
	}

}