[class^='primary-btn'], [class*='primary-btn']{
	cursor: pointer;
}

%button {
	background: $iet-dark-button;
	padding:10px;
	text-align: center;
	color:#fff;
	display:inline-block;
	min-width:100px;
	max-width:500px;
	transition: all .2s;
	text-decoration:none;
	font-weight:bold;

	@include MQ($min-desktop-width) {
		&:hover {
			text-decoration: underline;
			background: #fff!important;
			color: $iet-dark-button!important;
		}
	}

	&:focus {
	   outline: 0;
	}
}

.primary-btn, .btn-primary, button{

	@extend %button;

}

button{
	border:0;
}

.secondary-btn {
	@extend %button;
	background: $iet-light-button;
	color: #000;

	@include MQ($min-desktop-width) {
		&:hover {
			background: #000;
			color: $iet-light-button;
		}
	}
}

.primary-btn-arrow-right{
	font-size:0;
	position:relative;
	width:45px;
	height:38px;
	display:block;

	&:after{
		content:'';
		position:absolute;
		left:0;
		bottom:0;
		width:calc(100% - 14px);
		height:calc(100% - 14px);
		@include SvgIconHelper("images/arrow-right.svg", map-deep-get($iet-black-map, 100));
		margin:7px;
	}

	&:hover{

		background-color:#000;
		color:map-deep-get($iet-black-map, 100);

		&:after{
			@include SvgIconHelper("images/arrow-right.svg", #fff);
		}

	}
}

a:hover .primary-btn-arrow-right{

	background-color:#000;
	color:map-deep-get($iet-black-map, 100);

	&:after{
		@include SvgIconHelper("images/arrow-right.svg", #fff);
	}
}

.navbar-toggler {
	min-width: unset;
	height: 36px;
	padding: 5px;
}
