//main color scheme
$secondary-color: #006d9b;
$primary-color: #222; 
$page-text-color: #555; 

//navigation bar 
$navigation-bar-background-color: #444; 
$navigation-bar-background: $navigation-bar-background-color;
$navigation-bar-text-color: white; 
$navigation-bar-icon-color: $navigation-bar-text-color; 
$navigation-bar-link-color: #aaa;

//lower navigation bar
$lower-navigation-bar-background-color: #000;
$lower-navigation-bar-background: $lower-navigation-bar-background-color;
$lower-navigation-bar-text-color: white;
$lower-navigation-bar-icon-color: $lower-navigation-bar-text-color;
$lower-navigation-bar-link-color: #aaa;

//footer 
$footer-background-color: #373737; 
$pre-footer-background-color: $footer-background-color; 
$footer-text-color: white; 
$footer-icon-color: $footer-text-color; 
$footer-background: $footer-background-color; 

//color variants 
$primary-color-hover: lighten($primary-color, 7%); 
$primary-color-dark: darken($primary-color, 7%); 
$secondary-color-hover: lighten($secondary-color, 7%); 
$navigation-bar-background-color-hover: lighten($navigation-bar-background-color, 7%); 
$page-text-color-dark: darken($page-text-color, 10%);

$iet-standards-color: rgb(12, 120, 45);
$iet-standards-color-light: rgb(17,135,65);


// Purple
$iet-purple-map:(

	light:(
		100: rgb(160,70,180),
		80: rgb(179,107,195),
		60: rgb(198,144,210),
		40: rgb(217,181,225),
		20: rgb(236,218,240)
	),

	dark:(
		100: rgb(83,39,78),
		80: rgb(117,82,113),
		60: rgb(152,125,149),
		40: rgb(186,169,184),
		20: rgb(221,212,220)
	)

);



// Blue
$iet-blue-map:(

	light:(
		100: rgb(34,187,224),
		80: rgb(78,201,230),
		60: rgb(122,214,236),
		40: rgb(167,228,243),
		20: rgb(211,241,249)
	),

	dark:(
		100: rgb(0,58,102),
		80: rgb(51,97,133),
		60: rgb(102,137,163),
		40: rgb(153,176,194),
		20: rgb(204,216,224)
	)

);

// Green
$iet-green-map:(

	light:(
		100: rgb(170,200,30),
		80: rgb(187,211,75),
		60: rgb(204,222,120),
		40: rgb(221,233,165),
		20: rgb(238,244,210)
	),

	dark:(
		100: rgb(16,50,43),
		80: rgb(64,91,85),
		60: rgb(112,132,128),
		40: rgb(159,173,170),
		20: rgb(207,214,213)
	)

);


// Orange
$iet-orange-map:(

	light:(
		100: rgb(238,117,32),
		80: rgb(241,145,77),
		60: rgb(245,172,121),
		40: rgb(248,200,166),
		20: rgb(252,227,210)
	),

	dark:(
		100: rgb(103,31,32),
		80: rgb(133,76,77),
		60: rgb(164,121,121),
		40: rgb(194,165,166),
		20: rgb(225,210,210)
	)

);

$iet-black-map:(

	100: rgb(0,0,0),
	80: rgb(51,51,51),
	60: rgb(102,102,102),
	40: rgb(153,153,153),
	20: rgb(204,204,204)

);

$max-desktop-width: 1366px;
$min-desktop-width: 1025px;
$min-tablet-width: 768px;

$max-tablet-landscape-width: 1024px;

$side-margin-size: 15px;
$side-padding-size: 15px;

$min-two-column-width: $min-tablet-width;
$min-four-column-width: $min-desktop-width;

$iet-dark-button: rgb(0,0,0);
$iet-light-button: rgb(255,255,255);