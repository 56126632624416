#footer{

	.footer-quad-col{

		li{

			a{
				color:map-get($map: $iet-black-map, $key: 40);

				&:hover{
					text-decoration: underline;
				}
			}

		}

	}

}